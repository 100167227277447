import React from 'react'

import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'react-intl'

class GalleryIndex extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    const { currentPage, numPages, locale } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages

    const prevPage = `/${locale}/gallery/${
      currentPage - 1 === 1 ? '' : currentPage - 1
    }`
    const nextPage = `/${locale}/gallery/` + (currentPage + 1).toString()

    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow:
                'rgb(50, 115, 220) 0.5rem 0px 0px, rgb(50, 115, 220) -0.5rem 0px 0px',
              backgroundColor: 'rgb(50, 115, 220)',
              color: 'white',
              padding: '1rem',
            }}
          >
            <FormattedMessage id="navbar.gallery" />
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns is-multiline">
                {posts &&
                  posts.map(({ node: post }) => (
                    <div
                      className="is-parent column is-one-third"
                      key={post.id}
                    >
                      <article
                        className={
                          'blog-list-item tile is-child box notification'
                        }
                      >
                        <header>
                          <p className="post-meta">
                            <Link
                              className="title has-text-primary is-size-4"
                              to={post.fields.slug}
                            >
                              {post.frontmatter.title}
                            </Link>
                            <span> &bull; </span>
                            <span className="subtitle is-size-5 is-block">
                              {post.frontmatter.date}
                            </span>
                          </p>
                        </header>
                        {post.frontmatter.featuredimage ? (
                          <div className="featured-thumbnail">
                            <Link to={post.fields.slug}>
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: post.frontmatter.featuredimage,
                                  alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                }}
                              />
                            </Link>
                          </div>
                        ) : null}
                      </article>
                    </div>
                  ))}
              </div>
            </div>

            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: 0,
              }}
            >
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  <FormattedMessage id="page.previousPage" />
                </Link>
              )}

              {Array.from({ length: numPages }, (_, i) => (
                <li
                  key={`pagination-number${i + 1}`}
                  style={{
                    margin: 0,
                  }}
                >
                  {i + 1 === currentPage ? (
                    i + 1
                  ) : (
                    <Link to={`/${locale}/gallery/${i === 0 ? '' : i + 1}`}>
                      {i + 1}
                    </Link>
                  )}
                </li>
              ))}

              {!isLast && (
                <Link to={nextPage} rel="next">
                  <FormattedMessage id="page.nextPage" />
                </Link>
              )}
            </ul>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query galleryPageQuery(
    $skip: Int!
    $limit: Int!
    $filter: MarkdownRemarkFilterInput!
  ) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            langKey
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GalleryIndex
